<template>
  <div>
    <landing-page-header></landing-page-header>
    <!--Container-->
    <div class="container mt-5">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.news') }}</li>
        </ol>
      </nav>
      <div class="row">
        <div class="col-12 pt-3">
          <h2 class="title pl-1">{{ $t('menu.news') }}</h2>
        </div>
      </div>
      <!--Start code-->
      <div class="row">
        <div class="col-12 pb-5">
          <!--SECTION START-->
          <section class="row" v-if="data && data.length == 0">
            <div class="col-12 pb-2">
              <a-empty
                :image="Empty.PRESENTED_IMAGE_SIMPLE"
                :image-style="{
                  height: '60px',
                }"
              >
                <template #description>
                  <span>
                    {{ $t('general.noData') }}
                  </span>
                </template>
              </a-empty>
            </div>
          </section>
          <section class="row" v-if="data">
            <!--End slider news-->
            <news-card v-for="news in displayedData" :data="news" :key="news._id" />
          </section>
          <section v-else class="row">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <div class="spinner-grow text-primary" role="status"></div>
              </div>
            </div>
          </section>
          <div class="pagination">
            <a v-if="page != 1" @click="page = 1">«</a>
            <a v-if="page != 1" @click="page--">‹</a>
            <a
              v-for="pageNumber in pages.slice(page - 1, page + 5)"
              :class="{ selected: page == pageNumber }"
              :key="pageNumber"
              @click="page = pageNumber"
              >{{ pageNumber }}</a
            >
            <a v-if="page < pages.length" @click="page++">›</a>
            <a v-if="page < pages.length" @click="page = pages.length">»</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Vuejs slides
import NewsCard from '@/components/NewsCard'
import apiAxios from '@/services/axios'
import { Empty } from 'ant-design-vue'
import LandingPageHeader from '@/components/LandingPageHeader'


export default {
  name: 'News',
  components: { LandingPageHeader, NewsCard },
  data: () => ({
    slides: [
      {
        title: '',
        content: '',
        image: '/img/bg4.jpg',
      },
    ],
    Empty,
    data: null,
    page: 1,
    perPage: 9,
    pages: [],
  }),

  computed: {
    currentLocale () {
      return this.$i18n.locale.slice(0, 2);
    },
		displayedData () {
			return this.paginate(this.data.filter(data => data.title[this.currentLocale]));
		},
  },
  watch: {
    data() {
      this.setPages()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      apiAxios
        .post('/actualites/filter', {
          query: { status: 'active' },
        })
        .then((res) => {
          res.data.sort(function(x, y) {
        return (x.top === y.top)? 0 : x.top? -1 : 1;
    });
          this.data = res.data
        })
        .finally(() => {
          //this.tableLoading=false
        })
    },
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(data) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return data.slice(from, to)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';

.title {
  border-bottom: 2px solid $primary;
  width: 7em;
}
.arabicFont {
  font-family: 'Changa', sans-serif;
}
/* Pagination style */
@mixin transition-hover($x) {
  transition: all $x linear;
}

.pagination {
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;

    @include transition-hover(0.2s);

    &:hover,
    &.selected {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}
</style>
